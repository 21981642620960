import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Spinners from './components/Common/Spinner';
import ErrorBoundary from './components/Common/ErrorBoundries';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Spinners setLoading={() => {}} />}>
        <ErrorBoundary>
          {' '}
          {/* Wrap the ErrorBoundary around your components */}
          <React.Fragment>
            <Provider store={store}>
              <App />
            </Provider>
          </React.Fragment>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

// serviceWorker.unregister()
