import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Modal, ModalBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
//import { NavLink } from 'react-router-dom';
// Reactstrap
// import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from '../../store/actions';
import { toast } from 'react-toastify';
import { useCustomerAppointmentStore } from '../../zustand-store/customerAppointment.store';
//import { getAllLocations } from '../../axios/customerAppointment/customerAppointment.api';
//import { trimSentence } from '../../helpers/funtion_helper';
import { useAuthStore } from '../../zustand-store/auth.store';
// import { ROLES } from '../../constants/layout';
import { getAllBusiness } from '../../axios/business';
// import { useNavigate } from 'react-router-dom';
const Header = () => {
  // const [search, setsearch] = useState(false);
  const [businessLoader, setBusinessLoader] = useState(false);
  const [openBusinesses, setOpenBusinesses] = useState(false);
  const businessList = useCustomerAppointmentStore((state) => state.businessList);
  const { locationId, setLocationId, businessId, setBusinessId } = useAuthStore((state) => state);
  // const role = useAuthStore((state) => state.role);
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  useEffect(() => {
    loadAllbusiness();
    // loadAllLocations();
  }, []);
  const loadAllbusiness = async () => {
    try {
      setBusinessLoader(true);
      await getAllBusiness();
    } catch (error) {
      console.log(error, 'error');
      toast.error('Error fetching business');
    } finally {
      setBusinessLoader(false);
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">{/* <img src={logo} alt="" height="22" /> */}</span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logoLightSvg} alt="" height="22" /> */}
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>
            {!businessLoader && (
              <Button
                color="white"
                onClick={() => {
                  //  if (role == ROLES.SUPER_ADMIN) {
                  setOpenBusinesses(true);
                  // }
                  //else
                  //   // {

                  //     navigate(`/businessDetails/${businessId}`)
                  //    //
                  // //  }
                }}
                className="me-3 d-flex align-items-center justify-content-between mt-1 mb-1"
                style={{ border: '1px solid #ccc', borderRadius: 10 }}>
                {/* <span className="material-symbols-outlined text-primary d-none d-md-block">
                  business_center
                </span> */}
                <i
                  className="bx bx-briefcase  text-primary d-none d-md-block"
                  style={{ fontSize: 20 }}
                />
                &nbsp;
                {businessList?.find((x) => x.id === businessId)?.name || 'Select Business'} &nbsp;
                {/* <span  className="d-none d-sm-inline d-flex align-items-center"> */}
                {/* <span className="material-symbols-outlined text-primary d-none d-md-block">
                  location_on
                </span> */}
                <i className="bx bx-map text-primary d-none d-md-block" style={{ fontSize: 20 }} />
                &nbsp;
                <span className="d-none d-md-block">
                  {businessList
                    .find((x) => x.id === businessId)
                    ?.locations?.find((location) => location.id === locationId)?.name ||
                    'Select Location'}
                </span>
                {/* </span> */}
                <i className="mdi mdi-chevron-down ms-2" />
              </Button>
            )}

            <Modal
              isOpen={openBusinesses}
              toggle={() => setOpenBusinesses(!isOpen)}
              className="full-screen-modal">
              <div className="modal-header">
                <h5 className="modal-title">Select Business and Location</h5>

                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setOpenBusinesses(false)}
                  aria-label="Close"
                />
              </div>
              <ModalBody>
                {businessList?.map((business) => (
                  <div key={business.id}>
                    {/* Business Label */}
                    <div
                      className="modal-business-header"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '10px'
                      }}>
                      {/* <span
                        className={`material-symbols-outlined text-primary ${
                          businessId === business.id ? 'text-success' : 'text-secondary'
                        }`}>
                        business_center
                      </span> */}
                      <i
                        className={`bx bx-briefcase ${
                          businessId === business.id ? 'text-success' : 'text-secondary'
                        }`}
                        style={{ fontSize: 20 }}
                      />

                      <span
                        style={{ fontWeight: 'bold' }}
                        className={`${
                          businessId === business.id ? 'text-success' : 'text-secondary'
                        }`}>
                        {business.name}
                      </span>
                    </div>

                    {/* Locations under each business */}
                    {business.locations.map((location) => (
                      <div
                        key={`${business.id}-${location.id}`}
                        className="modal-location-item"
                        style={{ cursor: 'pointer', marginBottom: '5px' }}
                        onClick={() => {
                          setBusinessId(business.id);
                          setLocationId(location.id);
                          localStorage.setItem('businessId', business.id);
                          localStorage.setItem('locationId', location.id);
                          setOpenBusinesses(false); // Close modal on selection
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginLeft: 10
                          }}>
                          {/* Conditional Icon Rendering */}
                          {businessId === business.id && locationId === location.id ? (
                            <i className="bx bx-map  text-success" style={{ fontSize: 20 }} />
                          ) : (
                            <i className="bx bx-map  text-secondary" style={{ fontSize: 20 }} />
                          )}
                          {/* Location Name */}
                          <span
                            style={{ color: '#545A6D' }}
                            className={`${
                              businessId === business.id && locationId === location.id
                                ? 'text-success'
                                : 'text-secondary'
                            }`}>
                            {location.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </ModalBody>
            </Modal>
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown">
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show'
                    : 'dropdown-menu dropdown-menu-lg dropdown-menu-end p-0'
                }
                aria-labelledby="page-header-search-dropdown">
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen">
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header));
