import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Table,
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import JobListGlobalFilter from './GlobalSearchFilter';

// Column Filter
const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

Filter.propTypes = {
  column: PropTypes.shape({
    getFilterValue: PropTypes.func.isRequired,
    setFilterValue: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired
};

// Global Filter
const DebouncedInput = ({ value: initialValue, onChange, debounce = 500, ...props }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <div>
      {/* <i className="mdi mdi-magnify position-absolute input-icon mt-2 " style={{color:'gray'}}></i> */}
      <input
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={{ marginLeft: 5, width: '30vw' }}
      />
    </div>
  );
};

DebouncedInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounce: PropTypes.number
};

const TableContainer = ({
  buttonStyle,
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,
  isPagination,
  isGlobalFilter,
  paginationWrapper,
  SearchPlaceholder,
  pagination,
  // buttonClass,
  // buttonName,
  isAddButton,
  isCustomPageSize,
  handleUserClick,
  isJobListGlobalFilter,
  isServerPagination = false,
  onPageChange,
  currentPage,
  next,
  prev,
  totalPages,
  pageSize,
  setPageSize,
  setSearchTerm,
  searchTerm,
  serverSearching,
  pageSizeOptions = [10, 20, 30, 40, 50],
  allowFilters,
  setStartDate,
  setEndDate,
  setFiletrValue,
  filterValue,
  selectedRowIds,
  setSelectedRowIds,
  isPaidDropdownOpen,
  setIsPaidDropdownOpen,
  bulkLoader,
  values,
  changeStatus,
  tourId
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({ itemRank });
    return itemRank.passed;
  };

  const table = isServerPagination
    ? useReactTable({
        columns,
        data,
        filterFns: { fuzzy: fuzzyFilter },
        state: {
          columnFilters,
          globalFilter,
          pagination: { pageIndex: 0, pageSize: pageSize }
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
      })
    : useReactTable({
        columns,
        data,
        filterFns: { fuzzy: fuzzyFilter },
        state: {
          columnFilters,
          globalFilter
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
      });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    getState
  } = table;

  // const changePage = () => {
  //   table.setPageIndex((old) => old + 1);
  // };
  console.log(getState);
  function getDateRange(option) {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0); // Start of today
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    ); // End of today

    const startOfWeek = new Date(startOfDay);
    startOfWeek.setDate(startOfWeek.getDate() - (startOfWeek.getDay() || 7) + 1); // Adjust to Monday

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const formatDate = (date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;

    switch (option) {
      case 'All':
        return { startDate: '', endDate: '' };

      case 'Today':
        return {
          startDate: formatDate(startOfDay),
          endDate: formatDate(endOfDay)
        };

      case 'Yesterday': {
        const yesterdayStart = new Date(startOfDay);
        yesterdayStart.setDate(yesterdayStart.getDate() - 1);
        const yesterdayEnd = new Date(endOfDay);
        yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);
        return {
          startDate: formatDate(yesterdayStart),
          endDate: formatDate(yesterdayEnd)
        };
      }

      case 'Tomorrow': {
        const tomorrowStart = new Date(startOfDay);
        tomorrowStart.setDate(tomorrowStart.getDate() + 1);
        const tomorrowEnd = new Date(endOfDay);
        tomorrowEnd.setDate(tomorrowEnd.getDate() + 1);
        return {
          startDate: formatDate(tomorrowStart),
          endDate: formatDate(tomorrowEnd)
        };
      }

      case 'This Week':
        return {
          startDate: formatDate(startOfWeek),
          endDate: formatDate(endOfWeek)
        };

      default:
        throw new Error('Invalid option');
    }
  }
  return (
    <Fragment>
      <Row className="p-0 m-0">
        <Col sm={3}>
          {allowFilters && (
            <select
              className="w-100 fw-bold"
              style={{ border: '1px solid #ccc', padding: 8, marginTop: 10, borderRadius: 5 }}
              value={filterValue}
              onChange={(e) => {
                let date = getDateRange(e.target.value);
                setFiletrValue(e.target.value);
                setStartDate(date.startDate);
                setEndDate(date.endDate);
              }}>
              {['All', 'Today', 'Yesterday', 'Tomorrow', 'This Week'].map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          )}
        </Col>
        <Col sm={4}></Col>

        <Col sm={5} className=" d-flex align-items-center justify-content-end gap-2">
          {selectedRowIds &&
            Object.values(selectedRowIds).filter((row) => row.isChecked).length > 0 && (
              <Dropdown
                isOpen={isPaidDropdownOpen}
                onClick={(e) => console.log(e.target.value, 'e2')}
                onChange={(e) => console.log(e, 'e')}
                toggle={() => {
                  setIsPaidDropdownOpen(!isPaidDropdownOpen);
                }}>
                <DropdownToggle
                  // tag="button"
                  className={`btn bg-primary addContact-modal mb-2 px-4 mt-1`}
                  disabled={bulkLoader}>
                  {bulkLoader ? 'loading...' : 'Change Status'}{' '}
                  <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu className="text-start">
                  {values?.map((item) => {
                    return (
                      <DropdownItem key={item} onClick={() => changeStatus(item)}>
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            )}

          {isAddButton && (
            <div className="text-sm-end">
              <Button
                type="button"
                className={'btn bg-primary addContact-modal mb-2 px-4 mt-1'}
                style={buttonStyle}
                id={tourId}
                onClick={handleUserClick}>
                {/* <i className="mdi mdi-plus me-1"></i>  */}
                {'New'}
              </Button>
            </div>
          )}

          {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />}
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-between p-0 m-0">
        {/* Left Section */}
        <div className="d-flex align-items-center ">
          {selectedRowIds &&
            Object.values(selectedRowIds).filter((row) => row.isChecked).length > 0 && (
              <div className="d-flex " style={{ marginLeft: 15 }}>
                <div className="m-0 p-0 d-flex align-items-end ml-2 ">
                  <p className="m-0 p-0">
                    <i
                      className="fas fa-times-circle m-0 p-0"
                      style={{
                        marginRight: '8px',
                        color: 'red',
                        cursor: 'pointer'
                      }}
                      onClick={() => setSelectedRowIds({})}></i>
                    <strong className="m-0 p-0">&nbsp;Selected Rows:</strong>{' '}
                    {Object.values(selectedRowIds).filter((row) => row.isChecked).length}
                  </p>
                </div>
              </div>
            )}
        </div>

        {/* Right Section */}
        <div>
          {isGlobalFilter && !serverSearching && (
            <DebouncedInput
              value={globalFilter ?? ''}
              onChange={(value) => setGlobalFilter(String(value))}
              className="form-control search-box me-2 d-inline-block"
              placeholder={SearchPlaceholder}
            />
          )}
          {serverSearching && (
            <DebouncedInput
              value={searchTerm}
              onChange={(value) => setSearchTerm(value)}
              className="form-control search-box me-2  d-inline-block"
              placeholder={SearchPlaceholder}
            />
          )}
        </div>
      </div>

      {/* selectedRowIds is check if bulk selection feature is enbaled or not */}
      {/* selectedRowIds &&Object.values(selectedRowIds).filter((row) => row.isChecked).length > 0  */}

      <div className={divClassName || 'table-responsive'}>
        <Table
          hover
          className={tableClass}
          bordered={isBordered}
          style={{ height: '250px', overflowY: 'auto' }}>
          <thead className={theadClass}>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    style={{ background: 'white' }}
                    key={header.id}
                    colSpan={header.colSpan}
                    className={header.column.columnDef.enableSorting ? 'sorting sorting_desc' : ''}>
                    {header.isPlaceholder ? null : (
                      <Fragment>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler()
                          }}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: '',
                            desc: ''
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} />
                          </div>
                        ) : null}
                      </Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {isPagination && (
        <Row className="d-flex align-items-center justify-content-between">
          {isCustomPageSize && (
            <Col sm={12} md={4} className="d-flex align-items-center ">
              <select
                className="form-select pageSize mb-2 w-50"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  const newPageSize = Number(e.target.value);
                  if (isServerPagination) {
                    setPageSize(newPageSize);
                    table.setPageSize(newPageSize);
                  } else {
                    table.setPageSize(newPageSize);
                  }
                }}>
                {pageSizeOptions.map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {/* <Col sm={12} md={3} className="d-flex justify-content-center ">
            {!isServerPagination && (
              <div className="dataTables_info">
                Showing {table.getState().pagination.pageSize} of {data.length} Results
              </div>
            )}
          </Col> */}
          <Col sm={12} md={4} className="d-flex justify-content-end ">
            <div className={paginationWrapper}>
              <ul className={pagination}>
                <li
                  className={`paginate_button page-item previous ${
                    isServerPagination
                      ? prev == null
                        ? 'disabled'
                        : ''
                      : !getCanPreviousPage()
                        ? 'disabled'
                        : ''
                  }`}>
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => {
                      if (isServerPagination) {
                        onPageChange(currentPage - 1);
                      } else {
                        previousPage();
                      }
                    }}>
                    <i className="mdi mdi-chevron-left"></i>
                  </Link>
                </li>
                {!isServerPagination ? (
                  getPageOptions().map((item, key) => (
                    <li
                      key={key}
                      className={`paginate_button page-item ${
                        table.getState().pagination.pageIndex === item ? 'active' : ''
                      }`}>
                      <Link to="#" className="page-link" onClick={() => setPageIndex(item)}>
                        {item + 1}
                      </Link>
                    </li>
                  ))
                ) : (
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                  />
                )}
                <li
                  className={`paginate_button page-item next ${
                    isServerPagination
                      ? next == null
                        ? 'disabled'
                        : ''
                      : getCanNextPage()
                        ? ''
                        : 'disabled'
                  }`}>
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => {
                      if (isServerPagination) {
                        onPageChange(currentPage + 1);
                      } else {
                        nextPage();
                      }
                    }}>
                    <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string
      // Add any additional column properties here
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  divClassName: PropTypes.string,
  isBordered: PropTypes.bool,
  isPagination: PropTypes.bool,
  isGlobalFilter: PropTypes.bool,
  paginationWrapper: PropTypes.string,
  SearchPlaceholder: PropTypes.string,
  pagination: PropTypes.string,
  // buttonClass: PropTypes.string,
  // buttonName: PropTypes.string,
  isAddButton: PropTypes.bool,
  isCustomPageSize: PropTypes.bool,
  handleUserClick: PropTypes.func,
  isJobListGlobalFilter: PropTypes.bool,
  buttonStyle: PropTypes.object,
  isServerPagination: PropTypes.bool,
  onPageChange: PropTypes.func,

  currentPage: PropTypes.any,
  next: PropTypes.any,
  prev: PropTypes.any,
  totalPages: PropTypes.any,
  pageSize: PropTypes.any,
  setPageSize: PropTypes.any,
  setSearchTerm: PropTypes.any,
  searchTerm: PropTypes.any,
  serverSearching: PropTypes.any,
  pageSizeOptions: PropTypes.any,
  allowFilters: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  filterValue: PropTypes.any,
  setFiletrValue: PropTypes.any,
  selectedRowIds: PropTypes.any,
  setSelectedRowIds: PropTypes.any,
  isPaidDropdownOpen: PropTypes.any,
  setIsPaidDropdownOpen: PropTypes.any,
  bulkLoader: PropTypes.any,
  values: PropTypes.any,
  changeStatus: PropTypes.any,
  tourId: PropTypes.any
};

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  Pagination.propTypes = {
    totalPages: PropTypes.any,
    currentPage: PropTypes.any,
    onPageChange: PropTypes.any
  };
  const getPageNumbers = () => {
    const delta = 2; // Number of pages around the current page
    const range = [];
    const rangeWithDots = [];
    let lastPage = null;

    // Generate a range of pages
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= currentPage - delta && i <= currentPage + delta) // Pages around the current page
      ) {
        range.push(i);
      }
    }

    // Add "..." for skipped ranges
    range.forEach((page) => {
      if (lastPage !== null && page - lastPage > 1) {
        rangeWithDots.push('...');
      }
      rangeWithDots.push(page);
      lastPage = page;
    });

    return rangeWithDots;
  };

  const pages = getPageNumbers();

  return (
    <ul className="pagination">
      {/* Previous Button */}
      {/* <li className={`paginate_button page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <Link to="#" className="page-link" onClick={() => onPageChange(currentPage - 1)}>
          Previous
        </Link>
      </li> */}

      {/* Page Numbers */}
      {pages.map((page, index) => (
        <li
          key={index}
          className={`paginate_button page-item ${
            page === currentPage ? 'active' : page === '...' ? 'disabled' : ''
          }`}>
          {page === '...' ? (
            <span className="page-link">...</span>
          ) : (
            <Link to="#" className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </Link>
          )}
        </li>
      ))}

      {/* Next Button */}
      {/* <li className={`paginate_button page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <Link to="#" className="page-link" onClick={() => onPageChange(currentPage + 1)}>
          Next
        </Link>
      </li> */}
    </ul>
  );
};

export default TableContainer;
