import React, { useEffect, useState, useMemo } from 'react';
import withRouter from '../../components/Common/withRouter';
import TableContainer from '../../components/Common/TableContainer';
import Spinners from '../../components/Common/Spinner';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { useAuthStore } from '../../zustand-store/auth.store';
import { ToastContainer } from 'react-toastify';
//redux
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import VerticalLayout from '../../components/VerticalLayout';
import { todayAppointmentData } from '../../axios/appointment/index.api';

const TodayBookings = () => {
  //meta title
  document.title = 'Appointments | SeamlessSlot';

  const locationId = useAuthStore((state) => state.locationId);
  const businessId = useAuthStore((state) => state.businessId);

  //const dispatch = useDispatch();
  // const [contact, setContact] = useState();
  // validation
  const ContactsProperties = createSelector(
    (state) => state.contacts,
    (Contacts) => ({
      users: Contacts?.users,
      loading: Contacts?.loading
    })
  );

  const { loading } = useSelector(ContactsProperties);
  const [isLoading, setLoading] = useState(loading);

  const [data, setData] = useState();

  useEffect(() => {
    localStorage.setItem('path', '/todayBookingsReport');

    getData();
  }, [locationId]);
  const getData = async () => {
    //locationId
    setLoading(true);
    let data = await todayAppointmentData(parseInt(businessId));
    console.log(data, '===', businessId);
    // if (data.length > 0) {
    setData(data);
    // } else {
    //   setData([]);
    // }
    setLoading(false);
  };

  const columns = useMemo(
    () => [
      //   {
      //     header: 'Id',
      //     accessorKey: 'id',
      //     enableColumnFilter: false,
      //     enableSorting: true,
      //     cell: (cell) => {
      //       return (
      //         <div className="d-flex gap-3 align-items-center ">
      //           <p className="text-dark m-0">{cell.row.original.id}</p>
      //         </div>
      //       );
      //     }
      //   },
      {
        header: 'Location',
        accessorKey: 'locationName',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Confirmed Today',
        accessorKey: 'totalConfirmedBookingsToday',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Pending Today',
        accessorKey: 'totalPendingBookingsToday',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Confirmed Tomorrow',
        accessorKey: 'totalConfirmedBookingsTomorrow',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Pending Tomorrow',
        accessorKey: 'totalPendingBookingsTomorrow',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Created Today',
        accessorKey: 'totalCreatedAppointmentsToday',
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: 'Recent Booked',
        accessorKey: 'lastCreatedAppointmentOverall',
        enableColumnFilter: false,
        enableSorting: true
      }
    ],
    [businessId]
  );

  return (
    <VerticalLayout>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Appointments Report" breadcrumbItem="Appointments Report" />
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={data?.data?.data?.data || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        //   isAddButton={true}
                        //    handleUserClick={handleCustomerClicks}
                        buttonClass="btn bg-primary rounded-pill addContact-modal mb-2 px-4"
                        //   buttonName="New Service"
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                        tourId="open-add-service-modal"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    </VerticalLayout>
  );
};

export default withRouter(TodayBookings);
