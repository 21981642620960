import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import animationData from '../../../src/assets/images/somethingwenwrong.json'; // Adjust the path to your animation JSON file

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center text-center p-4">
          <Lottie animationData={animationData} style={{ width: '100%', height: '60vh' }} />

          <h2 className="text-danger mt-4">Something went wrong!</h2>
          {this.state.error && (
            <p className="text-muted">
              <strong>Error:</strong> {this.state.error.toString()}
            </p>
          )}

          {this.state.errorInfo && (
            <details className="text-muted" style={{ whiteSpace: 'pre-wrap', maxWidth: '80%' }}>
              <summary>View Error Details</summary>
              {this.state.errorInfo.componentStack}
            </details>
          )}

          <p
            className="fs-4 fw-bold text-primary text-decoration-underline cursor-pointer mt-3"
            onClick={() => window.location.reload()}
            style={{ cursor: 'pointer' }}>
            Reload
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
