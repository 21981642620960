import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withTranslation } from 'react-i18next';

// Redux
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import withRouter from '../../Common/withRouter';

// users
//import user1 from '../../../assets/images/users/avatar-1.jpg';
import { useAuthStore } from '../../../zustand-store/auth.store';
import { logoutDev, logoutProd } from '../../../axios/login/login.api';

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const firstName = useAuthStore((state) => state.firstName);
  const lastName = useAuthStore((state) => state.lastName);
  const navigate = useNavigate();

  const logout = async () => {
    import.meta.env.VITE_APP_ENV === 'dev' ? await logoutDev() : await logoutProd();
    localStorage.removeItem('locationId');
    navigate('/login');
  };
  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item  d-flex align-items-center justify-content-center"
          id="page-header-user-dropdown"
          tag="button">
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}

          <div className="d-flex items-center">
            <span
              style={{
                backgroundColor: '#000',
                color: '#fff',
                borderRadius: 5,
                minWidth: '24px', // Ensures it stays visible
                minHeight: '24px', // Keeps it square
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.8rem', // Adjusts for small screens
                padding: '4px'
              }}
              className="header-profile-user">
              {firstName?.charAt(0)} {lastName?.charAt(0)}
            </span>
          </div>

          <span className="d-none d-xl-inline-block ms-2 me-1">{firstName + ' ' + lastName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            onClick={() => {
              navigate('/profile');
            }}
            role="button">
            {' '}
            <Link to="/profile" className=" ">
              <i className="bx bx-group font-size-16 align-middle me-1 "></i>{' '}
              <span className="text-dark">{props.t('Profile')}</span>
            </Link>
          </DropdownItem>
          <DropdownItem onClick={logout} className="dropdown-item" role="button">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span className="text-danger">{props.t('Logout')}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
