import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const JoyrideContext = createContext();

export const JoyrideProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  return (
    <JoyrideContext.Provider value={{ run, setRun, stepIndex, setStepIndex }}>
      {children}
    </JoyrideContext.Provider>
  );
};

JoyrideProvider.propTypes = {
  children: PropTypes.node.isRequired // Ensure `children` is a valid React node and required
};

export const useJoyride = () => useContext(JoyrideContext);
