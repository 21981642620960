import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Joyride from 'react-joyride';
import { useJoyride } from './JoyrideContext'; // Import Joyride context

const JoyrideTour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { run, setRun, stepIndex, setStepIndex } = useJoyride();
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    // Start Joyride only once
    // if (!run && stepIndex === 0) {
    //   setTimeout(() => setRun(true), 1000);
    // }
  }, [run, stepIndex, setRun]);

  const steps = [
    {
      target: '#service',
      content: 'Click here to go to the Service page!',
      placement: 'right'
    },
    {
      target: '#open-add-service-modal',
      content: 'This is the main layout area.',
      placement: 'top'
    },
    {
      target: '#add-service',
      content: 'This is the main layout area.',
      placement: 'top'
    }
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if (status === 'finished' || status === 'skipped') {
      setRun(false);
      setStepIndex(0);
    }

    if (action === 'next' && index === 0) {
      setIsNavigating(true);
      setRun(false); // Pause Joyride before navigation
      navigate('/services');
    }

    if (index === 1 && type === 'step:after') {
      setTimeout(() => {
        const modalButton = document.querySelector('#open-add-service-modal');
        if (modalButton) {
          modalButton.click(); // Open the modal

          // Wait until modal is open before progressing to next step
          setTimeout(() => {
            setStepIndex(2); // Move to the next step after modal opens
            setRun(true);
          }, 1000); // Adjust delay if needed
        }
      }, 500);
    }

    if (type === 'step:after' && !isNavigating && index !== 1) {
      setStepIndex(index + 1);
    }
  };

  // Resume Joyride only after navigation is complete
  useEffect(() => {
    if (location.pathname === '/services' && stepIndex === 1 && !run) {
      setTimeout(() => {
        setRun(true);
        setIsNavigating(false);
      }, 500);
    }
  }, [location, stepIndex, run, setRun]);

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      disableOverlayClose={true}
      styles={{
        options: {
          zIndex: 10000 // Ensure Joyride is above everything
        }
      }}
    />
  );
};

export default JoyrideTour;
