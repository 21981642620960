import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { Link, useLocation } from 'react-router-dom';
import withRouter from '../Common/withRouter';

//i18n
import { withTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { ROLES } from '../../constants/layout';
import { useAuthStore } from '../../zustand-store/auth.store';

const SidebarContent = (props) => {
  const ref = useRef();
  const path = useLocation();
  const [isDropDownActive, setIsDropDownActive] = useState(false);

  const settingCollapse = ['business-settings', 'calender', 'patients'];
  const { role } = useAuthStore((state) => state);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    // if (settingCollapse.includes(path.pathname.split('/')[1])) {
    setIsDropDownActive(true);

    //}
  }, [settingCollapse]);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains('active')) {
        item.classList.remove('active');
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.remove('mm-show');
        }

        parent.classList.remove('mm-active');
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove('mm-show');

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('mm-active'); // li
            parent3.childNodes[0].classList.remove('mm-active');

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove('mm-show'); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('mm-show'); // li
                parent5.childNodes[0].classList.remove('mm-active'); // a tag
              }
            }
          }
        }
      }
    }
  };

  const getBasePath = () => {
    const segments = path.pathname.split('/').filter(Boolean); // Split and filter out empty segments
    return segments.length > 0 ? `/${segments[0]}` : ''; // Return the first segment
  };
  const activeMenu = useCallback(() => {
    // console.log(getBasePath())
    const pathName = getBasePath();

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    removeActivation(items);
    for (let i = 0; i < items.length; ++i) {
      console.log(pathName, items[i].pathname, 'url');
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    // Force "Reports" to stay active on specific pages
    if (
      path.pathname.includes('/reports') ||
      path.pathname.includes('/todayBookingsReport') ||
      path.pathname.includes('smsemailreport')
    ) {
      const reportsMenuItem = document.querySelector('a[href="/reports"]');
      if (reportsMenuItem) {
        activateParentDropdown(reportsMenuItem);
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu('#side-menu');
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t('Menu')} </li>
            {role === ROLES.SUPER_ADMIN && (
              <li>
                <Link to="/dashboard" className=" ">
                  <i className="bx bx-grid-alt"></i>

                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>
            )}
            {/* <li>
              <Link to="/dashboard-business-admin" className=" ">
                <i className="bx bx-grid-alt"></i>

                <span>{props.t('Dashboard Business admin')}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/dashboard-platform-admin" className=" ">
                <i className="bx bx-grid-alt"></i>

                <span>{props.t('Dashboard Platform admin')}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/calender" className=" ">
                <i className="bx bx-calendar"></i>
                <span>{props.t('Calendar')}</span>
              </Link>
            </li>
            <li>
              <Link to="/customers">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t('Customers')}</span>
              </Link>
            </li>
            <li>
              <Link to="/adminAppointment" className=" ">
                <i className="bx bx-task"></i>
                <span>{props.t('Appointments')}</span>
              </Link>
            </li>
            <li>
              <Link to="/payments" className=" ">
                <i className="bx bx-money"></i>

                <span>{props.t('Payments')}</span>
              </Link>
            </li>

            <li>
              <Link to="/paymentlogs" className=" ">
                <i className="bx bx-file"></i>

                <span>{props.t('Payment Logs')}</span>
              </Link>
            </li>
            <li>
              <Link to="/refunds" className=" ">
                <i className="bx bx-undo"></i>

                <span>{props.t('Refunds')}</span>
              </Link>
            </li>

            <li id="service">
              <Link to="/services" className=" ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t('Services')}</span>
              </Link>
            </li>

            {(role == ROLES.SUPER_ADMIN || role == ROLES.BUSINESS_ADMIN) && (
              <li>
                <Link to="/users" className=" ">
                  <i className="bx bx-group"></i>
                  <span>{props.t('Users')}</span>
                </Link>
              </li>
            )}
            {/* {role == ROLES.SUPER_ADMIN && (
              <li>
                <Link to="/todayBookingsReport" className=" ">
                  <i className="bx bx-task"></i>
                  <span>{props.t('Booking Report')}</span>
                </Link>
              </li>
            )} */}
            {role == ROLES.SUPER_ADMIN && (
              <li>
                <Link to="/reports" className=" ">
                  <i className="bx bx-task"></i>
                  <span>{props.t('Reports')}</span>
                </Link>
              </li>
            )}
            {(role == ROLES.SUPER_ADMIN || role == ROLES.BUSINESS_ADMIN) && (
              <li>
                <Link to="/business" className=" ">
                  <i className="bx bx-briefcase"></i>
                  <span>{props.t('Buisness')}</span>
                </Link>
              </li>
            )}
            {/* {role == ROLES.SUPER_ADMIN && (
              <li>
                <Link to="/reports" className=" ">
                  <i className="bx bx-briefcase"></i>
                  <span>{props.t('Reports')}</span>
                </Link>
              </li>
            )} */}
            <li className={'mm-active'}>
              <Link
                onClick={() => setIsDropDownActive(!isDropDownActive)}
                to="/#"
                //className="has-arrow"
                className="setting-style">
                <i className="bx bx-cog setting-icon"></i>
                <span>{props.t('Settings')}</span>
              </Link>
              <ul
                className={`sub-menu  ${isDropDownActive ? 'mm-show' : 'mm-collapsing'}`}
                aria-expanded="false">
                <li>
                  <Link to="/business-settings">{props.t('Business Settings')}</Link>
                </li>
                <li>
                  <Link to="/appointment-settings">{props.t('Appointment Settings')}</Link>
                </li>
                {/* <li>
                  <Link to="/payment-settings">{props.t('Payments')}</Link>
                </li> */}
                <li>
                  <Link to="/integration-settings">{props.t('Integration Settings')}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));
