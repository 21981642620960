import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardText } from 'reactstrap';
import PropTypes from 'prop-types';
import { isSquareConnected, disconnectSquarePay } from '../../axios/oauth/oauth';
import { useAuthStore } from '../../zustand-store/auth.store';
const PaymentIntegrationCard = ({ title, description, icon, integartionFlag, onClick }) => {
  const [isSquareIntegrated, setIsSquareIntegarted] = useState(false);
  const [loader, setLoader] = useState(true);
  const locationId = useAuthStore((state) => state.locationId);
  const [squareLocationId, setSquareLocationId] = useState(null);
  useEffect(() => {
    checkSquarePayConnected(locationId);
  }, [integartionFlag, locationId]);
  const checkSquarePayConnected = async (locationId) => {
    let response = await isSquareConnected(locationId);
    if (response.status === 200) {
      setIsSquareIntegarted(response?.data?.data?.status);
      setSquareLocationId(response?.data?.data?.squareLocationId);
      setLoader(false);
    }
  };
  const disconnect = async (locationId) => {
    setLoader(true);
    let response = await disconnectSquarePay(locationId);
    if (response.status === 200) {
      setIsSquareIntegarted(false);
      setLoader(false);
    }
  };
  return (
    <Card
      style={{ border: '1px solid', color: '#EFF2F7', cursor: 'pointer' }}
      onClick={() => {
        if (!isSquareIntegrated) {
          onClick();
        }
      }}>
      <CardBody>
        <div className="d-flex justify-content-between">
          <img style={{ width: '30px', height: '30px' }} src={icon} alt="" />
          {loader ? (
            <span className="text-warning">Loading...</span>
          ) : (
            <>
              {isSquareIntegrated ? (
                <Button className="btn btn-success">
                  <i className="mdi mdi-check" /> Connected
                </Button>
              ) : (
                <span className="text-primary">Please connect</span>
              )}
            </>
          )}
        </div>
        <p style={{ fontSize: '20px', color: '#495057' }} className="mt-2">
          {title}
        </p>
        <CardText style={{ color: '#74788D' }}>{description}</CardText>
        {isSquareIntegrated && (
          <CardText style={{ color: '#74788D' }}>Location Id : {squareLocationId}</CardText>
        )}
      </CardBody>
      {isSquareIntegrated && (
        <>
          {loader ? (
            <span className="text-warning">Loading...</span>
          ) : (
            <p
              className="text-danger text-end  mx-4"
              onClick={() => {
                let response = confirm('Are you sure disconnect?');

                // Check the user's response
                if (response) {
                  // User clicked "OK"
                  disconnect(locationId);
                } else {
                  // User clicked "Cancel"
                }
              }}>
              Disconnect
            </p>
          )}
        </>
      )}
    </Card>
  );
};

PaymentIntegrationCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isIntegrated: PropTypes.string,
  icon: PropTypes.any, // replace 'any' with the actual prop type
  onClick: PropTypes.any,
  integartionFlag: PropTypes.any
};

export default PaymentIntegrationCard;
