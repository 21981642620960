import { API } from '../api';
import axiosInstance from '../axiosConfig';

export const getAppointmentByLocation = async (id) => {
  const response = await axiosInstance.get(API.APPOINTMENT.GET_APPOINTMENT_BY_LOCATION + id);
  return response?.data?.data;
};
export const getAppointmentByYearMonth = async (yearmonth, id) => {
  const response = await axiosInstance.get(
    API.APPOINTMENT.GET_APPOINTMENT_BY_YEAR_MONTH + yearmonth + '/' + id
  );
  return response?.data?.data;
};

export const changeAppointmentStatus = async (id, status) => {
  const response = await axiosInstance.post(
    `${API.APPOINTMENT.CHANGE_APPOINTMENT_STATUS}${id}/${status}`
  );
  return response;
};
export const deleteAppointment = async (id) => {
  const response = await axiosInstance.delete(`${API.APPOINTMENT.DELETE}${id}`);
  return response;
};

export const cancelAppointment = async (id) => {
  const response = await axiosInstance.get(`${API.APPOINTMENT.CANCEL_APPOINTMENT}${id}`);
  return response?.data?.data;
};
export const getSerilizedAppointments = async (locationId, page, perPage, startDate, endDate) => {
  const response = await axiosInstance.get(
    `${API.APPOINTMENT.PAGINATION_APPOINTMENTS}?locationId=${locationId}&page=${page}&perPage=${perPage}&startDate=${startDate}&endDate=${endDate}`
  );
  return response;
};

export const getSerilizedAppointmentsSearch = async (body) => {
  const response = await axiosInstance.post(
    `${API.APPOINTMENT.PAGINATION_APPOINTMENTS_SEARCH}`,
    body
  );
  return response;
};

export const getAppointmemntById = async (id) => {
  const response = await axiosInstance.get(`${API.APPOINTMENT.BY_ID}` + id);
  return response;
};

export const bulkUpdateStatus = async (body) => {
  const response = await axiosInstance.patch(`${API.APPOINTMENT.BULK_UPDATE_STATUS}`, body);
  return response;
};

export const getConversationLogs = async (id) => {
  const response = await axiosInstance.get(API.APPOINTMENT.CONVERSTAION_LOGS + id);
  return response;
};

export const todayAppointmentData = async (id) => {
  const response = await axiosInstance.get(API.APPOINTMENT.TODAY_APPOINTMENT_DATA + id);
  return response;
};

export const smsAndEmailReport = async (id) => {
  const response = await axiosInstance.get(API.APPOINTMENT.SMS_EMAIL_REPORT + id);
  return response;
};
