import React, { useState } from 'react';
import SquareSvg from '../../../assets/svg/square.svg';
import GoogleTagManagerSvg from '../../../assets/svg/googleTagManager.svg';
import PaymentIntegrationCard from '../../../components/Common/PaymentCard';
import GoogletagmangerCard from '../../../components/Common/googleTagmanagerCard';
import { useAuthStore } from '../../../zustand-store/auth.store';
import { getSquarePayLocations, bindSquareLocation } from '../../../axios/oauth/oauth';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Container
  // Card,
  // CardBody
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import VerticalLayout from '../../../components/VerticalLayout';
const PaymentIntegrations = () => {
  document.title = 'Integration Settings | SeamlessSlot';
  const accessToken = useAuthStore((state) => state.accessToken);
  const locationId = useAuthStore((state) => state.locationId);
  const [locationPopup, setLocationPopup] = useState(false);
  const [squareLocations, setSquareLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [integartionFlag, setIntegrationFlag] = useState(false);

  const handleAuth = () => {
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const authWindow = window.open(
      import.meta.env.VITE_AUTH_URL + `?jid=${accessToken}&locationId=${locationId}`,
      'Square OAuth',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    // const messageListener = async (event) => {
    //   if (event.origin !== window.location.origin) {
    //     alert(event.origin);
    //     alert(window.location.origin);
    //     return;
    //   }

    //   const { data } = event;
    //   if (data.type === CONSTANTS.SQUARE_OAUTH_WINDOW_CALLBACK_EVENT) {
    //     // Handle the OAuth callback data
    //     console.log('OAuth data:', data.payload);
    //     const refreshToken = data.payload.refreshToken;
    //     const accessToken = data.payload.accessToken;

    //     // setLocationPopup(false);
    //     window.removeEventListener('message', messageListener);

    //     await createPaymentAccount({
    //       type: 'Square-Pay',
    //       accessToken,
    //       refreshToken,
    //       applicationId: 'empty',
    //       locationId
    //     });
    //     getSquareLocations();
    //   }
    // };

    // window.addEventListener('message', messageListener);

    const checkAuthWindowClosed = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(checkAuthWindowClosed);
        // window.removeEventListener('message', messageListener);
        getSquareLocations(locationId);
      }
    }, 1000);
  };

  const getSquareLocations = async () => {
    let data = await getSquarePayLocations(locationId);
    if (data.length > 0) {
      setSquareLocations(data);
      setLocationPopup(true);
    }
  };
  const saveLocation = async () => {
    let data = await bindSquareLocation(selectedLocationId, locationId);
    if (data?.status === 200) {
      toast.success('Square connected successfully');
      setLocationPopup(false);
      setIntegrationFlag(!integartionFlag);
    }
  };
  return (
    <VerticalLayout>
      <React.Fragment>
        <Container fluid>
          <div className="page-content ">
            <Breadcrumbs title="Integrations" breadcrumbItem="settings" />
            <Modal isOpen={locationPopup}>
              <ModalHeader>Sqaure pay Locations</ModalHeader>
              <ModalBody>
                <select
                  onChange={(e) => {
                    setSelectedLocationId(e.target.value);
                  }}
                  className="form-select"
                  aria-label="Default select example">
                  <option value="" selected>
                    Select square pay location
                  </option>
                  {squareLocations?.map((item, index) => (
                    <option key={index} value={item?.locationId}>
                      {' '}
                      {item?.name}
                    </option>
                  ))}
                </select>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={() => {
                    if (selectedLocationId == '') {
                      toast.error('Location not selected');
                    } else {
                      saveLocation();
                    }
                    // toggle(false);
                  }}
                  color="success">
                  Save
                </Button>
              </ModalFooter>
            </Modal>
            <div className="d-flex align-items-center bg-white  ">
              <p style={{ color: '#495057', fontWeight: '600', fontSize: '15px' }} className="p-2">
                Integrations
              </p>
            </div>
            <div className="row bg-white">
              <div className="d-flex align-items-center ">
                <p
                  style={{ color: '#495057', fontWeight: '600', fontSize: '15px', marginLeft: 10 }}>
                  Payment
                </p>
              </div>
              <div className="col-12 col-md-4">
                <PaymentIntegrationCard
                  description={
                    'Get paid for your services via Square, Cash App, Apple Pay or Google Pay.'
                  }
                  title={'Square'}
                  icon={SquareSvg}
                  integartionFlag={integartionFlag}
                  onClick={handleAuth}
                />
              </div>
            </div>
            <div className="row bg-white">
              <div className="d-flex align-items-center  ">
                <p
                  style={{ color: '#495057', fontWeight: '600', fontSize: '15px', marginLeft: 10 }}>
                  Analatics
                </p>
              </div>

              <div className="col-12 col-md-4">
                <GoogletagmangerCard
                  description={'Monitor how visitors engage with your custom Booking Page.'}
                  title={'Google Tag Manager'}
                  icon={GoogleTagManagerSvg}
                  integartionFlag={integartionFlag}
                  onClick={handleAuth}
                />
              </div>
            </div>
            <ToastContainer />
          </div>
        </Container>
      </React.Fragment>
    </VerticalLayout>
  );
};

export default PaymentIntegrations;
