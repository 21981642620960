import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import withRouter from '../../components/Common/withRouter';
import { Card, CardBody, Col, Container, Row, Table, Button } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import VerticalLayout from '../../components/VerticalLayout';

const TodayBookings = () => {
  document.title = 'Reports | SeamlessSlot';

  const navigate = useNavigate();

  const ContactsProperties = createSelector(
    (state) => state.contacts,
    (Contacts) => ({
      users: Contacts?.users,
      loading: Contacts?.loading
    })
  );

  const { loading } = useSelector(ContactsProperties);
  const [isLoading] = useState(loading);

  // Mock reports list (replace with API data)
  const [reports] = useState([
    { id: 1, title: 'Appointments Report', route: '/todayBookingsReport' },
    { id: 2, title: 'SMS and Email Report', route: '/smsemailreport' }
  ]);

  useEffect(() => {
    localStorage.setItem('path', '/reports');
  }, []);

  return (
    <VerticalLayout>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="mb-4">Reports List</h4>
                      <Table bordered striped>
                        <thead>
                          <tr>
                            <th>Title</th>

                            <th className="text-end">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {reports.map((report, index) => (
                            <tr key={report.id + index}>
                              <td>{report.title}</td>

                              <td className="text-end">
                                <Button color="white" onClick={() => navigate(report.route)}>
                                  View
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    </VerticalLayout>
  );
};

export default withRouter(TodayBookings);
